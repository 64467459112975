/* Tailwind CSS base styles */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Body styling */
body {
  @apply bg-gray-100 text-slate-900 dark:bg-slate-900 dark:text-slate-100 font-sans slashed-zero;
}

/* Global element styling */
html,
body,
#root,
.App {
  @apply h-full min-h-full bg-slate-900 text-slate-100 font-sans slashed-zero;
}

/* Navbar styling */
.navbar {
  @apply bg-slate-800 text-white flex items-center p-4;
}

/* Input and button base styling */
.input,
.button {
  @apply border border-slate-300 rounded dark:border-slate-700;
}

/* Specific input styling */
.input {
  @apply p-2;
}

/* Specific button styling */
.button {
  @apply ml-2 p-2 bg-blue-500 text-white rounded;
}

/* Root element styling */
#root {
  @apply flex flex-col;
}

/* Utility classes for text breaking */
.break-all {
  word-break: break-all;
}

.break-words {
  word-break: break-word;
}

/* Utility class for margin on medium screens */
.md\:ml-4 {
  @apply ml-4;
}

/* Keyframes for animations */
@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateX(-10px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Animation classes */
.animate-fade-in {
  animation: fade-in 0.3s ease-out forwards;
}

@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

.animate-float {
  animation: float 2s infinite ease-in-out;
}

.hover-animate-float:hover {
  animation: float 2s infinite ease-in-out;
}

/* Responsive flex container */
.flex-responsive {
  @apply flex flex-wrap md:flex-nowrap;
}

/* Add these at the end of your file */

@layer utilities {
  .animate-pulse {
    animation: pulse 3s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }

  @keyframes pulse {
    0%,
    100% {
      opacity: 0.25;
    }
    50% {
      opacity: 0.5;
    }
  }
}
